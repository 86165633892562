import PropTypes from 'prop-types'
import Modal from '../Modal/Modal'

const NoticeItem = ({ title, text }) => {
    return (
        <>
            <div className="flex justify-center items-center mb-8 w-full max-w-4xl">
                <Modal classes="flex flex-col md:flex-row items-center gap-2 w-full max-w-4xl">
                    <div className="border-b-4 md:border-b-0 md:border-r-4 text-xl font-semibold ">
                        <h2 className="p-4 md:p-12 self-center">{title}</h2>
                    </div>

                    <p className="p-4 text-center break-all md:break-normal md:text-start">
                        {text}
                    </p>
                </Modal>
            </div>
        </>
    )
}

NoticeItem.propTypes = {
    title: PropTypes.string,
    text: PropTypes.string,
}

export default NoticeItem
